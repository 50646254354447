import React, { useEffect, useRef, useState } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';

const FibonacciPivotPointsChart = ({ data, timeframe, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const getDefaultPivotLength = (tf) => {
    if (tf === '1d' || tf === '3d' || tf === '1w' || tf === '1M') {
      return 10;
    }
    return 16;
  };
  const [settings, setSettings] = useState({
    pivotLength: getDefaultPivotLength(timeframe),
    showRegular: true,
    showMissed: false,
    regularHighColor: '#ef5350',
    regularLowColor: '#26a69a',
    missedHighColor: '#ef5350',
    missedLowColor: '#26a69a',
    labelColor: '#ffffff',
    showFibLevels: true,
  });

  useEffect(() => {
    if (!chartContainerRef.current || !data || data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: {
        mode: 1,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      localization: {
        priceFormatter: price => price.toFixed(Math.max(szDecimals, actualDecimals)),
      },
    });

    chartRef.current = chart;

    const candleSeries = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });

    candleSeries.setData(data);

    // Calculate Fibonacci levels
    const calculateFibonacciLevels = () => {
      const high = Math.max(...data.map(d => d.high));
      const low = Math.min(...data.map(d => d.low));
      const diff = high - low;

      return [
        { level: 0, value: high },
        { level: 0.236, value: high - 0.236 * diff },
        { level: 0.382, value: high - 0.382 * diff },
        { level: 0.5, value: high - 0.5 * diff },
        { level: 0.618, value: high - 0.618 * diff },
        { level: 0.786, value: high - 0.786 * diff },
        { level: 1, value: low }
      ];
    };

    // Calculate Pivot Points
    const calculatePivots = () => {
      const pivots = [];
      const { pivotLength } = settings;

      for (let i = pivotLength; i < data.length - pivotLength; i++) {
        const highPivot = Math.max(...data.slice(i - pivotLength, i + pivotLength + 1).map(d => d.high));
        const lowPivot = Math.min(...data.slice(i - pivotLength, i + pivotLength + 1).map(d => d.low));

        if (data[i].high === highPivot) {
          pivots.push({ time: data[i].time, value: highPivot, type: 'high' });
        }
        if (data[i].low === lowPivot) {
          pivots.push({ time: data[i].time, value: lowPivot, type: 'low' });
        }
      }

      return pivots;
    };

    const fibLevels = calculateFibonacciLevels();
    const pivots = calculatePivots();

    // Add Fibonacci levels as horizontal lines
    const fibonacciSeries = fibLevels.map(level => {
      const series = chart.addLineSeries({
        color: `rgba(76, 175, 80, 1)`, //`rgba(76, 175, 80, ${0.8 - level.level * 0.5})`,
        lineWidth: 1,
        lineStyle: LineStyle.Solid,
        title: `Fib ${level.level.toFixed(3)}`,
        pane: 0,
      });

      series.setData(data.map(d => ({
        time: d.time,
        value: level.value
      })));

      return series;
    });

    // Add Pivot Point markers and lines
    const regularPivotSeries = chart.addLineSeries({
      color: settings.regularHighColor,
      lineWidth: 2,
      lastValueVisible: false,
    });

    const missedPivotSeries = chart.addLineSeries({
      color: settings.missedHighColor,
      lineStyle: LineStyle.Dashed,
      lineWidth: 2,
      lastValueVisible: false,
    });

    const drawPivots = () => {
      const regularPivots = [];
      const missedPivots = [];
      const markers = [];
      let lastPivot = null;

      pivots.forEach((pivot, index) => {
        const isValidPivot = index === 0 || index === pivots.length - 1 || (
          (pivot.type === 'high' && pivot.value > pivots[index - 1].value && pivot.value > pivots[index + 1].value) ||
          (pivot.type === 'low' && pivot.value < pivots[index - 1].value && pivot.value < pivots[index + 1].value)
        );

        if (isValidPivot) {
          regularPivots.push(pivot);
          
          markers.push({
            time: pivot.time,
            position: pivot.type === 'high' ? 'aboveBar' : 'belowBar',
            color: pivot.type === 'high' ? settings.regularHighColor : settings.regularLowColor,
            shape: 'circle',
            text: pivot.type === 'high' ? 'P' : 'B'
          });

          if (lastPivot) {
            const lineColor = pivot.value > lastPivot.value ? settings.regularLowColor : settings.regularHighColor;
            regularPivotSeries.setData([
              { time: lastPivot.time, value: lastPivot.value, color: lineColor },
              { time: pivot.time, value: pivot.value, color: lineColor }
            ]);
          }
          lastPivot = pivot;
        } else {
          missedPivots.push(pivot);
        }
      });

      if (settings.showRegular) {
        regularPivotSeries.setData(regularPivots);
        candleSeries.setMarkers(markers);
      } else {
        regularPivotSeries.setData([]);
        candleSeries.setMarkers([]);
      }

      if (settings.showMissed) {
        missedPivotSeries.setData(missedPivots);
      } else {
        missedPivotSeries.setData([]);
      }
    };

    drawPivots();

    // Show/hide Fibonacci levels based on settings
    const toggleFibLevels = (show) => {
      fibonacciSeries.forEach(series => {
        series.applyOptions({
          visible: show
        });
      });
    };

    toggleFibLevels(settings.showFibLevels);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, settings, timeframe, szDecimals, isSpot, actualDecimals]);

  const handleSettingChange = (setting, value) => {
    setSettings(prevSettings => ({ ...prevSettings, [setting]: value }));
  };

  return (
    <div>
      <div ref={chartContainerRef} />
      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Chart Settings</h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div>
            <label className="block">Pivot Length</label>
            <input
              type="number"
              value={settings.pivotLength}
              onChange={(e) => handleSettingChange('pivotLength', parseInt(e.target.value))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block">Show Regular Pivots</label>
            <input
              type="checkbox"
              checked={settings.showRegular}
              onChange={(e) => handleSettingChange('showRegular', e.target.checked)}
              className="mt-2"
            />
          </div>
          <div>
            <label className="block">Show Missed Pivots</label>
            <input
              type="checkbox"
              checked={settings.showMissed}
              onChange={(e) => handleSettingChange('showMissed', e.target.checked)}
              className="mt-2"
            />
          </div>
          <div>
            <label className="block">Show Fibonacci Levels</label>
            <input
              type="checkbox"
              checked={settings.showFibLevels}
              onChange={(e) => handleSettingChange('showFibLevels', e.target.checked)}
              className="mt-2"
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-sm text-gray-600">
          This chart combines Fibonacci retracement levels with pivot points analysis. The Fibonacci levels are shown as dashed horizontal lines, while pivot points are marked with P (peaks) and B (bottoms). The color intensity of Fibonacci levels decreases as the retracement level increases. Regular pivot points are connected with solid lines, while missed pivot points can be shown with dashed lines.
        </p>
      </div>
    </div>
  );
};

export default FibonacciPivotPointsChart;